import type { FormatterMap, ProductRoutesOptions, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createCheckoutFormatters,
  productDetailFormatters,
  productListFormatter,
} from '@grandvisionhq/www-next/formatters'

const productRoutesOptions: ProductRoutesOptions = {
  accessories: 'solglasogon/tillbehor',
  'contact-lenses': 'linser',
  frames: 'glasogon',
  solutions: 'linser/kontaktlinsvatskor',
  sunglasses: 'solglasogon',
  'ready-readers': 'lasglasogon',
}

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createSimpleFormatter('boka-tid'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: {
      shipping: 'leverans',
      overview: 'oversikt',
      payment: 'betalning',
    },
  }),
  ...productListFormatter(productRoutesOptions),
  ...productDetailFormatters(productRoutesOptions),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/leverans', 'checkout.shipping'],
  ['/checkout/oversikt', 'checkout.overview'],
  ['/checkout/betalning', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/express-checkout', 'express-checkout'],

  ['/boka-tid/(.*)?', 'appointments.book'],
  ['/omboka-tid', 'appointments.reschedule'],
  ['/avboka-tid', 'appointments.cancel'],
]
